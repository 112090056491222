const players = [
  {
    name: 'Jonathan',
    number: '+6587427184'
  },
  {
    name: 'Max',
    number: '+6594517971'
  },
  {
    name: 'Rae',
    number: '+61431536778'
  },
  {
    name: 'Linus',
    number: '+61423464102'
  },
  {
    name: 'Cara',
    number: '+6590485907'
  },
  {
    name: 'Nicholas',
    number: '+6590039948'
  },
  {
    name: 'Nicole',
    number: '+6591852822'
  },
  {
    name: 'Marc',
    number: '+6597918284'
  },
  {
    name: 'Ian',
    number: '+6596678570'
  },
  {
    name: 'Ryan',
    number: '+447561716587'
  },
  {
    name: 'Madeleine',
    number: '+6597521154'
  },
  {
    name: 'Kellyn',
    number: '+6597377672'
  },
]

export default players
